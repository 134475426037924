import React , {useState,useEffect,lazy, Suspense} from 'react'; 
import {Route,Switch} from 'react-router-dom'
const Footer = lazy(() => import('./Components/Footer'));
const Navbar = lazy(() => import('./Components/Navabar'));
const Home = lazy(() => import('./Pages/Home'));
const Contact = lazy(() => import('./Pages/ContactUs'))
const News = lazy(() => import('./Components/NewsAndMedia'));
// const DocumentController = lazy(() => import('./Components/DocumentSection'))
const TransportSupport = lazy(() => import('./Components/TransportSupport'))
const LogisticSupport = lazy(() => import('./Components/LogisticSupport'))
const Warehousing = lazy(() => import('./Components/Warehousing'))
const Error = lazy(() => import('./Components/ErrorPage'));

export default function App() {
  const [isLoading, setLoading] = useState(true);

  function fakeRequest() {
    return new Promise(resolve => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    fakeRequest().then(() => {
      const el = document.querySelector(".Lbody");
      if (el) {
        el.remove();
        setLoading(!isLoading);
      }
    });
  }, [isLoading]);

  if (isLoading) {
    return null;
  }
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>
      <Navbar />
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/contact-us" component={Contact}/>
          <Route exact path="/newsandmedia" component={News}/>
          {/* <Route exact path="/services/generalController" component={DocumentController}/> */}
          <Route exact path="/services/transportation" component={TransportSupport}/>
          <Route exact path="/services/logistics" component={LogisticSupport}/>
          <Route exact path="/services/warehousing" component={Warehousing}/>
          {/* <Route exact path="/services/customsClearance" component={CustomsClearance}/> */}
          <Route component={Error} status={404}/>
        </Switch>
      <Footer/>
      </Suspense>
    </>
  )
}
